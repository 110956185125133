var render = function () {
  var _vm$dashboardData;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseLayout', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v("Dashboard")];
      },
      proxy: true
    }])
  }, [[_c('v-row', {
    staticClass: "ma-1"
  }, [_c('v-col', {
    staticClass: "card-grid"
  }, [_vm.dashboardData ? _c('BaseIconCard', {
    attrs: {
      "dataCy": "pendingCourseParticipants",
      "icon": "mdi-account-details",
      "value": _vm.dashboardData.participantApplicationCount,
      "headline": "Nye søknader",
      "to": {
        name: _vm.DashboardRouteNames.NewApplications
      }
    }
  }) : _vm._e(), _vm.dashboardData && _vm.hasPreApprove ? _c('BaseIconCard', {
    attrs: {
      "icon": "mdi-account-details",
      "value": (_vm$dashboardData = _vm.dashboardData) === null || _vm$dashboardData === void 0 ? void 0 : _vm$dashboardData.preApprovedParticipantCount,
      "headline": "Forhåndsgodkjente søkere",
      "to": {
        name: _vm.DashboardRouteNames.PreApproved
      }
    }
  }) : _vm._e(), _c('BaseIconCard', {
    attrs: {
      "dataCy": "rapporter",
      "icon": "mdi-chart-histogram",
      "headline": "Rapporter",
      "href": "https://app.powerbi.com/groups/me/apps",
      "target": "_blank",
      "value": "Power BI"
    }
  })], 1)], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }