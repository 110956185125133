var render = function () {
  var _vm$dashboardApiData$, _vm$dashboardApiData$2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.hasMemberOrgAccess ? _c('div', {
    staticClass: "d-flex justicy-center align-center dashboard-container"
  }, [_c('GuestUserDashboardPage')], 1) : _vm._e(), _vm.currentEmployeeId ? _c('BaseLayout', {
    staticClass: "px-0",
    attrs: {
      "displayTabs": _vm.isFilterOpen
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v("Dashboard")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "color": "white",
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              _vm.isFilterOpen = !_vm.isFilterOpen;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-filter-variant")]), _vm._v(" Filter ")], 1)];
      },
      proxy: true
    }, {
      key: "tabs",
      fn: function fn() {
        return [_c('EmployeeSearchInput', {
          attrs: {
            "label": "Velg ansatt",
            "cypressId": "dashboardFilterSelect"
          },
          on: {
            "input": _vm.onSelectEmployee
          },
          model: {
            value: _vm.currentEmployeeId,
            callback: function callback($$v) {
              _vm.currentEmployeeId = $$v;
            },
            expression: "currentEmployeeId"
          }
        })];
      },
      proxy: true
    }], null, false, 649113692)
  }, [[_c('v-row', {
    staticClass: "ma-1"
  }, [_c('v-col', {
    staticClass: "card-grid"
  }, [_vm.dashboardApiData ? _c('BaseIconCard', {
    attrs: {
      "dataCy": "pendingCurriculums",
      "icon": "mdi-format-list-checks",
      "value": _vm.dashboardApiData.curriculumForApprovalCount,
      "headline": "Studieplaner til godkjenning",
      "to": {
        name: _vm.DashboardRouteNames.PlansForApproval
      }
    }
  }) : _vm._e(), _vm.dashboardApiData ? _c('BaseIconCard', {
    attrs: {
      "data-cy": "pendingWorkingHoursIconCard",
      "dataCy": "pendingWorkingHours",
      "headline": "Arbeidstimer til godkjenning",
      "icon": "mdi-account-clock",
      "value": _vm.dashboardApiData.workingHourEntriesForApprovalCount,
      "to": {
        name: _vm.DashboardRouteNames.PendingWorkingHours
      }
    }
  }) : _vm._e(), _vm.dashboardApiData ? _c('BaseIconCard', {
    attrs: {
      "dataCy": "pendingCourseParticipants",
      "icon": "mdi-account-details",
      "value": _vm.dashboardApiData.unreadCourseApplicationsCount,
      "headline": "Nye påmeldinger og søknader",
      "to": {
        name: _vm.DashboardRouteNames.NewApplications
      }
    }
  }) : _vm._e(), _vm.dashboardApiData ? _c('BaseIconCard', {
    attrs: {
      "dataCy": "courseWithLowAttendanceCount",
      "icon": "mdi-account-alert",
      "value": _vm.dashboardApiData.courseWithLowAttendanceCount,
      "headline": "Lavt fremmøte",
      "to": {
        name: _vm.DashboardRouteNames.CoursesWithLowAttendance
      }
    }
  }) : _vm._e(), _vm.isExtendedCourseAdmin ? _c('BaseIconCard', {
    attrs: {
      "dataCy": "grantRequests",
      "icon": "mdi-cash-multiple",
      "value": _vm.grantRequests || '-',
      "headline": "Tilretteleggingstilskudd",
      "to": {
        name: _vm.DashboardRouteNames.FacilitationGrants
      }
    }
  }) : _vm._e(), _vm.dashboardApiData ? _c('BaseIconCard', {
    attrs: {
      "dataCy": "unreadMessages",
      "icon": "mdi-forum",
      "value": _vm.unreadMessages,
      "headline": "Uleste meldinger",
      "to": {
        name: _vm.DashboardRouteNames.UnreadCourseMessages
      }
    }
  }) : _vm._e(), _vm.dashboardApiData ? _c('BaseIconCard', {
    attrs: {
      "dataCy": "newDocuments",
      "icon": "mdi-file-document",
      "value": _vm.dashboardApiData.unreadNewDocumentsCount,
      "headline": "Nye dokumenter",
      "to": {
        name: _vm.DashboardRouteNames.NewDocuments
      }
    }
  }) : _vm._e(), _vm.dashboardApiData ? _c('BaseIconCard', {
    attrs: {
      "dataCy": "changedCourseParticipants",
      "icon": "mdi-account-box-outline",
      "value": _vm.dashboardApiData.changedCourseParticipantApplicationsCount,
      "headline": "Forbundskurs - Endrede deltakere",
      "to": {
        name: _vm.DashboardRouteNames.ChangedParticipants
      }
    }
  }) : _vm._e(), _c('BaseIconCard', {
    attrs: {
      "dataCy": "rapporter",
      "icon": "mdi-chart-histogram",
      "headline": "Rapporter",
      "href": "https://app.powerbi.com/groups/me/apps",
      "target": "_blank",
      "value": "Power BI"
    }
  }), _vm.dashboardApiData ? _c('BaseIconCard', {
    attrs: {
      "icon": "mdi-content-paste",
      "value": "".concat((_vm$dashboardApiData$ = _vm.dashboardApiData.unsigedAndFailedExpiredContractsContracts) === null || _vm$dashboardApiData$ === void 0 ? void 0 : _vm$dashboardApiData$.unsignedContracts, " / ").concat((_vm$dashboardApiData$2 = _vm.dashboardApiData.unsigedAndFailedExpiredContractsContracts) === null || _vm$dashboardApiData$2 === void 0 ? void 0 : _vm$dashboardApiData$2.failedAndExpiredContracts),
      "headline": "Usignerte / feilede kontrakter",
      "to": {
        name: _vm.DashboardRouteNames.Contracts
      }
    }
  }) : _vm._e()], 1)], 1)]], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }