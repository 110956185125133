
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseIconCard from "@/components/shared/BaseIconCard.vue";
import { DashboardRouteNames } from "@/shared/enums/RouteNames/dashboardRouteNames.enum";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import {
  ApiGetGuestEmployeewithMemberOrganizationDto,
  ApiGetGuestUserDashboardDto,
  ApiGetMemberOrganizationFeatureSettingDto,
} from "@/api/generated/Api";
import { api } from "@/api/api";

export default defineComponent({
  name: "GuestUserDashboard",
  components: { BaseLayout, BaseIconCard },
  setup() {
    const dashboardData = ref<ApiGetGuestUserDashboardDto>();
    const currentGuestUser = ref<ApiGetGuestEmployeewithMemberOrganizationDto>();
    const hasPreApprove = ref(false);
    const activeFeatureSettings = ref<ApiGetMemberOrganizationFeatureSettingDto[]>([]);

    const getCurrentUser = async () => {
      currentGuestUser.value = (await api.guestside.getGuestUserCurrentGuestEmployeeAsync()).data;
      await checkIfUserMemberOrgHasPreApprove(currentGuestUser.value.memberOrganization?.id || 0);
    };

    const checkIfUserMemberOrgHasPreApprove = async (id: number) => {
      if (!id) {
        return;
      }

      if (!currentGuestUser.value?.memberOrganization) {
        return;
      }

      const memberOrganization = (
        await api.guestside.getMemberOrganizationFeatureSettings1(currentGuestUser.value.memberOrganization.id)
      ).data;

      if (memberOrganization?.usePreApprovalForCourses) {
        hasPreApprove.value = true;
      }
    };

    const getActiveFeatureSettings = async () => {
      activeFeatureSettings.value = (await api.guestside.getActivatedMemberOrganizationFeatureSettings1()).data;
    };

    onMounted(async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        dashboardData.value = (await api.guestside.getDashboardSelfAsync1()).data;
        await getActiveFeatureSettings();
        await getCurrentUser();
      });
    });

    return {
      DashboardRouteNames,
      dashboardData,
      hasPreApprove,
    };
  },
});
