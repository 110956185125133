
import { api, dashboardApi } from "@/api/api";
import {
  ApiCourseResponsibleFilter,
  ApiGetCurrentEmployeeDto,
  ApiGetDashboardDto,
  ApiGetEmployeeDto,
} from "@/api/generated/Api";
import { AuthRole } from "@/auth/auth.constants";
import { authService } from "@/auth/authService";
import EmployeeSearchInput from "@/components/common/EmployeeSearchInput.vue";
import GuestUserDashboardPage from "@/components/dashboard/GuestUserDashboardPage.vue";
import BaseIconCard from "@/components/shared/BaseIconCard.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import { featureFlags } from "@/featureFlags";
import { DashboardUser } from "@/shared/constants/dashboardUser";
import { GrantApplicationStatus } from "@/shared/enums/GrantApplicationStatus.enum";
import { CourseRouteNames } from "@/shared/enums/RouteNames/courseRouteNames.enum";
import { DashboardRouteNames } from "@/shared/enums/RouteNames/dashboardRouteNames.enum";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { hasMemberOrgAccess } from "@/shared/helpers/accessLevelApiAdapter";
import { getCurrentUser, getDashboardHttpClient, getDashboardUserId } from "@/shared/helpers/dashboardHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "DashboardPage",
  components: { BaseIconCard, BaseLayout, EmployeeSearchInput, GuestUserDashboardPage },
  setup() {
    const dashboardApiData = ref<ApiGetDashboardDto>();
    const unreadMessages = ref("0");
    const grantRequests = ref();
    const employees = ref<ApiGetEmployeeDto[]>();
    const currentEmployeeId = ref<number>();
    const currentUser = ref<ApiGetCurrentEmployeeDto>();

    const isFilterOpen = ref(true);
    const isExtendedCourseAdmin = authService.hasRoles(AuthRole.ModifyExtendedCourse);

    onMounted(async () => {
      if (hasMemberOrgAccess) {
        return;
      }
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        await loadEmployees();
        currentUser.value = await getCurrentUser();
        currentEmployeeId.value = await getDashboardUserId();
        updateCurrentDashboardUser(currentEmployeeId.value);
        await Promise.all([loadDashboardApi(), loadUnreadMessagesCount(), getGrantApplications()]);
      });
    });

    const updateCurrentDashboardUser = (userId: number) => {
      if (!userId) {
        return;
      }
      const currentEmployeeOrgId = getEmployeeOrganizationId(userId);
      currentEmployeeId.value = userId;
      window.sessionStorage.setItem(DashboardUser.USER_ID, userId.toString());
      window.sessionStorage.setItem(DashboardUser.USER_ORG_ID, currentEmployeeOrgId.toString());
      dashboardApi.instance = getDashboardHttpClient(currentUser.value?.organizationId?.toString() ?? "");
    };

    const onSelectEmployee = async (employeeId?: number) => {
      if (employeeId === undefined) {
        return;
      }

      updateCurrentDashboardUser(employeeId);
      await loadDashboardApi();
      await loadUnreadMessagesCount();
    };

    const loadDashboardApi = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        dashboardApiData.value = (
          await api.dashboard.getDashboardAsync({
            userId: currentEmployeeId.value,
            courseResponsibleFilter: ApiCourseResponsibleFilter.SUPERVISOR_AND_LECTURER,
            getCurriculumForApprovalCount: true,
          })
        ).data;
      });
    };

    const getGrantApplications = async () => {
      if (!isExtendedCourseAdmin) {
        return;
      }
      const response = (await dashboardApi.course.getFilteredGrantApplicationsAsync()).data;
      if (!response?.length) {
        return;
      }
      grantRequests.value = response.filter(
        (grantApplication) => (grantApplication.applicationStatus = GrantApplicationStatus.SentForApproval)
      ).length;
    };

    const loadUnreadMessagesCount = async () => {
      const response = (
        await dashboardApi.messaging.searchMessagesKursadmin({
          CourseResponsibleFilter: ApiCourseResponsibleFilter.SUPERVISOR_AND_LECTURER,
          UserId: currentEmployeeId.value,
          IsRead: false,
          Sender: false,
        })
      ).data.items;
      if (response) {
        unreadMessages.value = response.length.toString();
      }
    };

    const loadEmployees = async () => {
      employees.value = (await api.user.getEmployeesAsync()).data;
    };

    const getEmployeeOrganizationId = (employeeId: number) =>
      employees.value?.find((employee) => employee.id === employeeId)?.organizationId ?? 0;

    return {
      isExtendedCourseAdmin,
      isFilterOpen,
      unreadMessages,
      grantRequests,
      dashboardApiData,
      DashboardRouteNames,
      CourseRouteNames,
      hasMemberOrgAccess,
      currentEmployeeId,
      onSelectEmployee,
      firstName: authService.user.given_name,
      featureFlags,
    };
  },
});
